import { communeDateTimeZoneToUTC, utcToCommuneDateTimeZone } from '@/communes/composables';
import { DeepRequired } from '@/stores/GlobalStore';
import { CommuneRegistrationDto, ContactInfoDto } from '@/typings/api';
import { CommuneAdminDto, CommuneDto, ContractDataDto, InitialCommuneDto, LocationDto } from '../commune';
export const baseUserInformation = () => {
  return {
    userUuid: '',
    email: '',
    salutation: '',
    title: '',
    firstname: '',
    lastname: '',
    institution: '',
    function: '',
    department: '',
    phone: '',
    street: '',
    number: '',
    zipCode: '',
    city: '',
    addressExtra: '',
  };
};

export class CommuneDtoImplementation implements Required<Omit<CommuneDto, 'contractData' | 'status' | 'modifiedAt'>> {
  id = 0;
  srUuid = 0;
  uuid = '';
  status = {
    exists: false,
    active: false,
    reportingPeriodValid: false,
  };
  manualName = '';
  officialName = '';
  type = 0;
  contractData: Required<ContractDataDto> = {
    reportStart: null as unknown as string,
    reportEnd: null as unknown as string,
    adminStart: null as unknown as string,
    adminEnd: null as unknown as string,
  };
  areaId = 0;
  country = 0;
  intlId = '';
  federalState = 0;
  regionKey = '';
  createdAt = '';
  nameArticle = '';
  timezone = 'Europe/Berlin';
  county = '';
  district = '';
  parentUuid = '';
  centerLocation: Required<LocationDto> = {
    latitude: 0,
    longitude: 0,
  };
  registratorContact = baseUserInformation();
  billingContact = baseUserInformation();

  membership = [];
  bundle = [];
  population = 0;
  urlSlug = '';
  customTypeArticle = '';
  customTypeName = '';
  isVirtual: boolean = false;
  stadtradelnAttended: boolean = false;
  usable: boolean = true;
  platform: 'NONE' | 'MPR_FORWARD' | 'MPR_ONLY' = 'NONE';
  countryLabel="";
  federalStateLabel="";

  // Internal time range for the create and update
  adminRange: [number, number] = [Date.now(), Date.now()];
  reportRange: [number, number] = [Date.now(), Date.now()];

  /*----------  Remove when implemented  ----------*/
  licenseID = 0;

  constructor(dto?: CommuneDto) {
    if (dto) {
      Object.assign(this, dto);
      dto.contractData ? this.setTimeRange() : null;
    }
  }
  setTimeRange() {
    if (this.contractData) {
      this.reportRange = [Date.parse(this.contractData?.reportStart), Date.parse(this.contractData?.reportEnd)];
      this.adminRange = [Date.parse(this.contractData?.adminStart), Date.parse(this.contractData?.adminEnd)];
    }
  }

  get IsCommuneActive(): boolean {
    //Color Red
    return this.status.active && this.status.reportingPeriodValid;
  }

  get IsInactive(): boolean {
    //Color Gray
    return this.status.active && !this.status.reportingPeriodValid;
  }

  get IsInvalid(): boolean {
    //Color Gray + Stripes overlay on json
    return !this.status.active && !this.status.reportingPeriodValid;
  }

  //Selected commune reporting period
  get RPeriod(): {
    start: string;
    end: string;
  } {
    return {
      start: this.contractData?.reportStart || '',
      end: this.contractData?.reportEnd || '',
    };
  }

  // get data for commune registration
  get getRegistrationData(): CommuneRegistrationDto {
    return {
      uuid: this.uuid,
      manualName: this.manualName,
      contractData: this.contractData,
      centerLocation: this.centerLocation,
      regionKey: this.regionKey,
      communeTypeId: this.type,
      countryId: this.country,
      county: this.county,
      district: this.district,
      federalStateId: this.federalState,
      officialName: this.officialName,
      licenceId: this.licenseID,
      customTypeArticle: this.customTypeArticle,
      customTypeName: this.customTypeName,
    };
  }
  formatStringDate(d: string | number): string {
    if (typeof d === 'string') {
      const dateDe = d.includes('.') ? d.split('.') : false;
      const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      const date = dateDe ? d.replace(pattern, '$3-$2-$1') : d.replaceAll('/', '-');

      return new Date(date).toISOString().split('.')[0];
    } else {
      return new Date(d).toISOString().split('.')[0];
    }
  }
  setContractData() {
    this.contractData = {
      adminStart: this.formatStringDate(this.adminRange[0]),
      adminEnd: this.formatStringDate(this.adminRange[1]),
      reportStart: this.formatStringDate(this.reportRange[0]),
      reportEnd: this.formatStringDate(this.reportRange[1]),
    };
  }
  formattedCommuneNew(): Required<CommuneRegistrationDto & { timezone?: string }> {
    const {
      contractData,
      country,
      centerLocation,
      district,
      type,
      federalState,
      officialName,
      manualName,
      regionKey,
      nameArticle,
      licenseID,
      county,
      bundle,
      membership,
      urlSlug,
      population,
      platform,
      customTypeArticle,
      customTypeName,
      timezone,
      stadtradelnAttended,
    } = this;

    // Transform the utc time to the communeTimezone
    let contractDataBaseOnCommune = {
      ...contractData,
    };

    if (contractData.reportStart) {
      contractDataBaseOnCommune = {
        adminStart: utcToCommuneDateTimeZone({ date: contractData.adminStart || Date.now().toString() }),
        adminEnd: utcToCommuneDateTimeZone({ date: contractData.adminEnd || Date.now().toString() }),
        reportStart: utcToCommuneDateTimeZone({ date: contractData.reportStart || Date.now().toString() }),
        reportEnd: utcToCommuneDateTimeZone({ date: contractData.reportEnd || Date.now().toString() }),
      };
    }

    return {
      centerLocation,
      district,
      communeTypeId: type,
      countryId: country,
      contractData: contractDataBaseOnCommune,
      federalStateId: federalState,
      officialName,
      manualName,
      regionKey,
      county,
      billingDetails: baseUserInformation(),
      registrator: baseUserInformation(),
      bundle,
      coordinators: [],
      creatorUuid: '',
      licenceId: licenseID,
      membership,
      nameConflict: false,
      population,
      registratorIsCoordinator: false,
      uuid: this.uuid,
      urlSlug,
      platform,
      customTypeArticle,
      customTypeName,
      timezone,
      stadtradelnAttended,
    };
  }
}
export function getAddressFromUserBillingOrContact(p: ContactInfoDto) {
  return {
    zipCode: p.zipCode,
    city: p.city,
    country: '',
    street: p.streetAndNumber,
    state: p.addressExtra,
    suburb: p.addressExtra,
    // @ts-ignore
    houseNumber: p.number,
  };
}
export function transformToPendingCommuneFormat(c: Required<DeepRequired<CommuneAdminDto>>): Required<CommuneRegistrationDto> {
  const {
    contractData,
    country,
    centerLocation,
    district,
    type,
    federalState,
    officialName,
    manualName,
    regionKey,
    county,
    uuid,
    bundle,
    membership,
    population,
    urlSlug,
    platform,
    timezone,
    customTypeArticle,
    customTypeName,
  } = c.details;
  const registrator = c.contacts.find((c) => c.system === 'RADAR' && c.type === 'REGISTRATION');
  const billing = c.contacts.find((c) => c.system === 'RADAR' && c.type === 'BILLING');

  const registrationAddress = registrator?.streetAndNumber?.split(' ') || [];
  const billingAddress = billing?.streetAndNumber?.split(' ') || [];
  const registrationStreet = registrationAddress[0] || '';
  const registrationHouseNumber = registrationAddress[1] || '';
  const billingStreet = billingAddress[0] || '';
  const billingHouseNumber = billingAddress[1] || '';

  // Transform the utc time to the communeTimezone
  const contractDataBaseOnCommune = {
    adminStart: utcToCommuneDateTimeZone({ date: contractData.adminStart }),
    adminEnd: utcToCommuneDateTimeZone({ date: contractData.adminEnd }),
    reportStart: utcToCommuneDateTimeZone({ date: contractData.reportStart }),
    reportEnd: utcToCommuneDateTimeZone({ date: contractData.reportEnd }),
  };
  return {
    centerLocation,
    district,
    communeTypeId: type,
    countryId: country,
    contractData: contractDataBaseOnCommune,
    federalStateId: federalState,
    officialName,
    manualName,
    regionKey,
    county,
    bundle,
    coordinators: [],
    creatorUuid: '',
    licenceId: 3,
    membership,
    nameConflict: false,
    population,
    registratorIsCoordinator: false,
    uuid,
    billingDetails: {
      ...billing,
      address: {
        zipCode: billing?.zipCode || '',
        city: billing?.city || '',
        country: '',
        street: billingStreet,
        state: billing?.addressExtra || '',
        suburb: billing?.addressExtra || '',
        // @ts-ignore
        houseNumber: billingHouseNumber,
      },
    },
    registrator: {
      ...registrator,
      address: {
        zipCode: registrator?.zipCode || '',
        city: registrator?.city || '',
        country: '',
        street: registrationStreet,
        state: registrator?.addressExtra || '',
        suburb: registrator?.addressExtra || '',

        // addressExtra: c.registratorContact.addressExtra,
        // @ts-ignore
        houseNumber: registrationHouseNumber,
      },
    },
    urlSlug,
    platform,
    timezone,
    customTypeArticle,
    customTypeName,
  };
}

export function transformToInitialCommune(
  d: Required<CommuneRegistrationDto>,
  usable = true,
  timeZone = 'Europe/Berlin',
): Required<InitialCommuneDto> {
  const registrator: ContactInfoDto = d.registrator;
  const billing: ContactInfoDto = d.billingDetails;

  //Transform the contract data to utc here
  const contractData: Required<ContractDataDto> = {
    reportStart: communeDateTimeZoneToUTC({ date: d.contractData.reportStart as string }),
    reportEnd: communeDateTimeZoneToUTC({ date: d.contractData.reportEnd as string }),
    adminStart: communeDateTimeZoneToUTC({ date: d.contractData.adminStart as string }),
    adminEnd: communeDateTimeZoneToUTC({ date: d.contractData.adminEnd as string }),
  };

  return {
    contacts: [
      { ...registrator, system: 'RADAR', type: 'REGISTRATION' },
      { ...billing, system: 'RADAR', type: 'BILLING' },
    ],
    protectContract: false,
    centerLocation: d.centerLocation,
    bundle: d.bundle,
    contractData: contractData,
    communeTypeId: d.communeTypeId,
    countryId: d.countryId,
    county: d.county,
    membership: d.membership,
    urlSlug: d.urlSlug,
    district: d.district,
    federalStateId: d.federalStateId,
    population: d.population,
    regionKey: d.regionKey,
    manualName: d.manualName,
    officialName: d.officialName ? d.officialName : `${d.manualName}`,
    srUuid: Date.now(),
    timezone: 'Europe/Berlin',
    platform: d.platform,
    includeStub: false,
    uuid: d.uuid,
    customTypeName: d.customTypeName,
    customTypeArticle: d.customTypeArticle,
    parentUuid: '',
    usable,
    contactUpdateType: 'RADAR',
  };
}
