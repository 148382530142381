import { REPORT_STATUS } from './../typings/api/ReportStatus';
import RoadReport from '@/services/ReportService';
import {
  CommuneTypesDto,
  ReasonCategoryDto,
  ReasonGroupDto,
  ReportTypesDto,
  V1CommuneUuidTypesParameters,
  WorkDurationDto,
  V1ReporthidestatusOrReasonParameters as SettingsVisibilityToggle,
  V1CommuneperiodParameters,
  StatusDto,
} from '@/typings/api';
import { defineStore } from 'pinia';
import { useUserStore } from './UserStore';
import { ReportStatusSorting } from '@/settings/HelperIcons';

export type DeepRequired<T> = {
  [K in keyof T]: Required<DeepRequired<T[K]>>;
};

export type USER_ROLES = 'coordinator' | 'administrator' | 'reporter';
interface GlobalStoreInterface {
  reportTypes: DeepRequired<ReportTypesDto> | null;
  communeTypes: DeepRequired<CommuneTypesDto> | null;
  reportStatusMapper: Map<REPORT_STATUS, number>;

  systemSettings: {
    maintenanceMode: boolean;
  };
}

export const useGlobalStore = defineStore('global', {
  state: (): GlobalStoreInterface => ({
    reportTypes: null,
    communeTypes: null,
    reportStatusMapper: new Map<REPORT_STATUS, number>([]),
    systemSettings: {
      maintenanceMode: false,
    },
  }),
  getters: {
    GET_REASON_CATEGORIES: (state) => {
      return state.reportTypes?.reasonCategories;
    },
    GET_REASON_GROUP: (state): Required<ReasonGroupDto>[] => {
      return state.reportTypes?.reasonGroups || [];
    },
    GET_REASON_BY_GROUP_ID: (state) => {
      return (id: number) => state.reportTypes?.reasonCategories?.filter((item) => item.groupId === id);
    },
    GET_REASON_BY_ID: (state) => {
      return (id: number): ReasonCategoryDto | undefined => state.reportTypes?.reasonCategories?.find((item) => item.id === id);
    },
    GET_REPORT_STATUS: (state) => {
      return state.reportTypes?.status?.sort((a, b) => ReportStatusSorting[a.key] - ReportStatusSorting[b.key]) || [];
    },
    GET_MAX_STRING_SIZE: (state): number => {
      return state.reportTypes?.maxCommentSize || 5000;
    },
    GET_WORK_DURATION_OPTIONS: (state): Required<WorkDurationDto>[] => {
      return state.reportTypes?.durations || [];
    },
    GET_ROLES_OPTIONS: (state): Map<number, string> => {
      const roles = state.reportTypes ? state.reportTypes.userRoles || [] : [];
      return new Map(roles.map((o) => [o.id, o.name]));
    },
    GET_ROLES_OPTIONS_BY_NAME: (state): Map<USER_ROLES | string, number> => {
      const roles = state.reportTypes ? state.reportTypes.userRoles || [] : [];
      return new Map(roles.map((o) => [o.name, o.id]));
    },
    GET_ROLES: (state) => {
      return state.reportTypes?.userRoles || [];
    },

    /*----------  Commune specific  ----------*/
    GET_REASON_BY_GROUP_ID_COMMUNE: (state) => {
      return (id: number) =>
        state.communeTypes?.reasonCategories
          ?.filter((item) => item.usable && item.groupId === id)
          .sort((a, b) => a.sort - b.sort);
    },
    GET_REPORT_STATUS_COMMUNE: (state) => {
      return state.communeTypes?.availableStatus || [];
    },
  },
  actions: {
    async ACTION_GET_REPORT_TYPES() {
      if (this.reportTypes) {
        return;
      }
      try {
        const { status, data } = await RoadReport.GetReportTypes();
        if (status === 200) {
          this.reportTypes = data as DeepRequired<ReportTypesDto>;

          this.reportStatusMapper = new Map((data.status as Required<StatusDto>[]).map((o) => [o.key, o.id]));
        }
      } catch (error) {
        const userStore = useUserStore();
        await userStore.ACTION_REFRESH_TOKEN();
      }
    },
    async ACTION_GET_TYPES_BY_COMMUNE(p: V1CommuneUuidTypesParameters) {
      const { status, data } = await RoadReport.GetTypesByCommune(p);
      if (status === 200) {
        this.communeTypes = data;
      }
    },
    async ACTION_COMMUNE_REASON_STATUS_TOGGLE_VISIBILITY(p: SettingsVisibilityToggle) {
      const { status, data } = await RoadReport.ToggleVisibility(p);
      if (status === 200) {
        console.log(data);
      }
    },
    async ACTION_COMMUNE_ARCHIVE_UPDATE(p: V1CommuneperiodParameters) {
      const { status, data } = await RoadReport.UpdateArchivePeriod(p);
      if (status === 200) {
        console.log(data);
      }
    },
    async ACTION_GET_MAINTENANCE_MODE() {
      const { status, data } = await RoadReport.GetMaintenanceMode();
      if (status === 200) {
        this.systemSettings = data;
      }
    },
  },
});
