import { useReportsAllStore } from '@/stores/AllReportsStore';
import { useUserStore } from '@/stores/UserStore';
import {
  CommuneRelationshipDto,
  V1CommuneUuidRelationParameters,
  V1CommuneUuidNotifyParameters,
  FederalState,
} from './../typings/commune/index';
import { RemovableRef, useLocalStorage } from '@vueuse/core';
import CommuneService, { CommuneDetail, CommuneResponse, UpdateCommuneParams } from '@/services/CommuneService';
import {
  type V1CommuneUuidAreaParameters,
  type V1CommuneUuidParameters,
  type V1CommuneParameters,
  type V1CommuneadminParameters,
  CommuneAdminDto,
  InitialCommuneDto,
  V1StubParameters,
  type TypesDto,
} from '@/typings/commune';
import { defineStore } from 'pinia';
import { CommuneDtoImplementation } from '@/typings/dto/CommuneDto';
import { AxiosResponse } from 'axios';
import { PaginationInfo } from 'naive-ui';
import {
  type CommuneDetailsDto,
  type V1CommuneUuidInfoParameters,
  type CommuneRegistrationDto,
  type V1CommuneregisterTempIdParameters,
  type V1CommuneregisterParameters,
  UserCommuneDto,
  V1CommuneUuidSyncParameters,
  CommuneDto,
} from '@/typings/api';
import { DeepRequired } from './GlobalStore';
import { type PendingCommuneOverview, ReportServiceCommune } from '@/services/ReportServiceCommune';
import { Page } from '@/typings/general/Page';

export type CommuneRegistrationLayout = 'REGISTER_COMMUNE_SUCCESS' | 'REGISTER_COMMUNE_ERROR' | 'REGISTER_COMMUNE_VIEW';
export interface CommuneSelectedOption {
  label: string;
  key: string;
}

interface CommuneStateInterface {
  communes: CommuneDetail[];
  selectedCommune: CommuneDtoImplementation | null;
  communesList: CommuneDetail[];
  selectedCommuneDetails: CommuneDtoImplementation | null;
  communeToUpdate: CommuneDetail | null;
  communeActiveGeoJson: string | null;
  COMMUNE_LIST_SELECTED: CommuneDetail | null;

  dropdownSelectedCommune: RemovableRef<{ value: null | string; uuid: string | null; manualName?: string }>;

  communeDetailsList: Required<CommuneAdminDto>[];
  currentCommunePagination: RemovableRef<PaginationInfo>;
  currentCommuneInformation: CommuneDetailsDto;
  communeEditModal: boolean;
  regionTypes: DeepRequired<TypesDto> | null;

  pendingCommunes: Page<PendingCommuneOverview>;
  pendingCommuneDetails: Required<CommuneRegistrationDto> | null;

  communeRelationShips: Required<CommuneRelationshipDto>;

  isLoadingCommunes: boolean | undefined;
  communesThatNeedSync: Required<CommuneDto>[];
  isCurrentRouteSystemCommunes: boolean;
}

export type CommuneUserLocation = {
  lat: number;
  long: number;
};

const basePendingCommunes: Page<PendingCommuneOverview> = {
  content: [],
  empty: false,
  first: false,
  last: false,
  number: 0,
  numberOfElements: 0,
  size: 20,
  totalElements: 0,
  totalPages: 0,
  sort: {
    empty: false,
    sorted: false,
    unsorted: false,
  },
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 0,
    paged: false,
    unpaged: false,
    sort: {
      empty: false,
      sorted: false,
      unsorted: false,
    },
  },
};

export const useCommuneStore = defineStore('CommuneStore', {
  state: (): CommuneStateInterface => ({
    communes: [],
    selectedCommune: new CommuneDtoImplementation(),
    communesList: [],
    selectedCommuneDetails: null,
    communeToUpdate: null,
    communeActiveGeoJson: null,
    COMMUNE_LIST_SELECTED: null,
    dropdownSelectedCommune: useLocalStorage('dropdownSelectedCommune', { value: null, uuid: null }),
    communeDetailsList: [],
    currentCommunePagination: useLocalStorage('PAGINATION', {
      page: 1,
      pageSize: 20,
      endIndex: 0,
      pageCount: 1,
      startIndex: 0,
      itemCount: 10,
    }),
    currentCommuneInformation: {
      email: '',
      freeText: '',
      phone: '',
      responsible: '',
    },
    communeEditModal: false,
    pendingCommunes: basePendingCommunes,
    pendingCommuneDetails: null,
    regionTypes: null,
    communeRelationShips: {
      parent: {},
      childCommunes: [],
    },
    isLoadingCommunes: undefined,
    communesThatNeedSync: [],
    isCurrentRouteSystemCommunes: false,
  }),
  getters: {
    GET_All_COMMUNES: (state): CommuneDetail[] => {
      return state.communes;
    },
    GET_All_COMMUNES_AS_MAP: (state): Map<string, CommuneDetail> => {
      return new Map(state.communes.map((c) => [c.uuid, c]));
    },
    GET_All_FEDERAL_STATE: (state): Map<number | undefined, FederalState> => {
      //transform object to array
      const states: { [key: string]: FederalState } = state.regionTypes?.federalStates || {};
      const array: FederalState[] = Object.keys(states).map((i) => ({ ...states[i] }));

      return new Map(array.map((c) => [c.id, c]));
    },
    GET_All_COMMUNES_DETAILS: (state): Required<CommuneAdminDto>[] => {
      return state.communeDetailsList;
    },
    GET_SELECTED_COMMUNE: (state): CommuneDtoImplementation | null => {
      return state.selectedCommune;
    },
    GET_ACTIVE_GEOJSON: (state) => {
      return state.communeActiveGeoJson;
    },
    GET_COMMUNE__PAGINATION: (state): PaginationInfo => {
      return state.currentCommunePagination;
    },
  },
  actions: {
    /*----------  Commune   ----------*/
    async ACTION_GET_REGION_TYPES() {
      const { status, data } = await CommuneService.GetRegionTypes();

      if (status === 200) {
        this.regionTypes = data;
      }
    },
    async ACTION_GET_ALL_COMMUNES(options?: V1CommuneParameters) {
      const userStore = useUserStore();

      const usable = userStore.GET_CURRENT_KEYCLOAK_USER?.isAdmin ? 'ALL' : 'USABLE';
      const { status, data } = await CommuneService.GetAllCommunes({ ...options, usable });

      if (status === 200) {
        // this.selectedCommune = new CommuneDtoImplementation();
        // this.communeActiveGeoJson = null;
        this.communes = data;
      }
    },
    async ACTION_GET_ALL_COMMUNES_ADMIN(p: V1CommuneadminParameters) {
      this.isLoadingCommunes = true;
      const bundle = this.isCurrentRouteSystemCommunes ? 'all' : 'radar';
      const { status, data } = await CommuneService.GetAllCommunesAsAdmin(p, bundle);

      if (status === 200) {
        this.communeDetailsList = data.content;
        this.currentCommunePagination.itemCount = data.totalElements;
        this.currentCommunePagination.page = data.number;
        this.currentCommunePagination.pageCount = data.totalPages;
        this.currentCommunePagination.pageSize = data.size;
      }
      this.isLoadingCommunes = undefined;
    },
    async ACTION_GET_COMMUNE_BY_LOCATION(loc: CommuneUserLocation) {
      const { status, data } = await CommuneService.GetCommuneByUserLocation(loc);
      if (status === 200) {
        this.communes = data;
      }
    },
    async ACTION_GET_COMMUNE_INFORMATION({ uuid, scope = 'none' }: { uuid: string; scope?: string }) {
      const { status, data } = await ReportServiceCommune.GetCommuneInformation({ uuid, scope });
      if (status === 200) {
        this.currentCommuneInformation = data;
      }
    },
    async ACTION_UPDATE_COMMUNE_INFORMATION_FULL({
      stadtradelnAttended,
      uuid,
    }: {
      stadtradelnAttended: boolean;
      uuid: string;
    } & CommuneDetailsDto) {
      await this.ACTION_COMMUNE_INFORMATION_UPDATE({
        uuid,
        data: {
          stadtradelnAttended,
        },
        scope: 'bundle',
      });
    },
    async ACTION_GET_COMMUNE_UUID(UUID: V1CommuneUuidParameters, setOnDropdown = false) {
      const res = await CommuneService.GetCommuneByUuid(UUID);
      if (res.status === 200) {
        this.selectedCommune = new CommuneDtoImplementation(res.data);

        setOnDropdown
          ? (this.dropdownSelectedCommune = {
            value: this.selectedCommune.manualName,
            uuid: this.selectedCommune.uuid,
          })
          : null;
        try {
          const communeInformation = await ReportServiceCommune.GetCommuneInformation({ uuid: UUID.uuid, scope: 'full' });
          this.currentCommuneInformation = communeInformation.data;
        } catch (error) {
          console.log(error);
        }
      }
      return res;
    },
    async ACTION_GET_COMMUNE_APP_UUID({ uuid, name }: Required<UserCommuneDto>) {
      const globalStore = useReportsAllStore();

      await this.ACTION_GET_COMMUNE_UUID({ uuid });
      await this.ACTION_GEOJSON_BY_UUID({ uuid });

      await globalStore.ACTION_GET_COMMUNE_STATS({ communeUuid: uuid });
      await globalStore.ACTION_GET_ALL_REPORTS_BY_COMMUNE({ p: { communeUuid: uuid, size: 500 } });
    },

    async ACTION_GET_COMMUNE_URL(p: V1CommuneParameters, setOnDropdown = false, asParent = false) {
      const main = await CommuneService.GetCommuneByUrl(p);

      if (main.status === 200) {
        const theCommune = main.data.length ? main.data[0] : null;
        if (theCommune) {
          let uuid = theCommune.uuid;

          const res = await CommuneService.GetCommuneByUuid({
            uuid: uuid as string,
          });

          if (asParent && res.data.parentUuid) {
            uuid = res.data.parentUuid;
            const parent = await CommuneService.GetCommuneByUuid({
              uuid: res.data.parentUuid as string,
            });

            this.selectedCommune = new CommuneDtoImplementation(parent.data);
          } else {
            this.selectedCommune = new CommuneDtoImplementation(res.data);
          }

          try {
            const communeInformation = await ReportServiceCommune.GetCommuneInformation({
              uuid: uuid as string,
              scope: 'full',
            });
            this.currentCommuneInformation = communeInformation.data;
          } catch (error) {
            console.log(error);
          }
          setOnDropdown
            ? (this.dropdownSelectedCommune = { value: this.selectedCommune.manualName, uuid: this.selectedCommune.uuid })
            : null;
        }
      }
      return main;
    },
    async ACTION_GET_COMMUNE_RELATIONSHIP({ uuid }: { uuid: string }) {
      const relation = await CommuneService.GetCommuneRelationShips({ uuid });
      this.communeRelationShips = relation.data;
    },
    async ACTION_UPDATE_COMMUNE_RELATIONSHIP(p: V1CommuneUuidRelationParameters) {
      await CommuneService.UpdateCommuneRelationship(p);
      this.communeRelationShips = {
        parent: {},
        childCommunes: [],
      };
    },
    async ACTION_GET_COMMUNE_UUID_DATA(UUID: V1CommuneUuidParameters) {
      const res = await CommuneService.GetCommuneByUuid(UUID);
      return res;
    },
    async ACTION_FETCH_COMMUNE_DETAILS_TRANSFORM_PENDING(p: V1CommuneUuidParameters) {
      try {
        const res = await CommuneService.GetCommuneByUuid(p);

        if (res.status === 200) {
          const c = new CommuneDtoImplementation(res.data).formattedCommuneNew();
          return c;
        }
        throw Error('commune.feedback.notFound');
      } catch (error) {
        throw Error('commune.feedback.notFound');
      }
    },

    async ACTION_DELETE_COMMUNE_UUID(p: V1CommuneUuidParameters) {
      const res = await CommuneService.DeleteCommuneByUuid(p);
      return res;
    },
    async ACTION_UPDATE_COMMUNE_UUID(p: { uuid: string; data: InitialCommuneDto }) {
      const res = await CommuneService.UpdateCommuneByUuid(p);
      if (res.status === 200) {
        this.selectedCommune = new CommuneDtoImplementation(res.data);
      }
      return res;
    },
    async ACTION_CREATE_COMMUNE({
      commune,
      json,
    }: {
      commune: InitialCommuneDto;
      json: CommuneResponse;
    }): Promise<AxiosResponse<CommuneAdminDto>> {
      try {
        const res = await CommuneService.CreateNewCommune({ commune });
        if (res.status === 201) {
          const uuid = res.data.details?.uuid as string;
          await CommuneService.UpdateCommuneGeoJsonByUuid({ uuid, data: json });
        }
        return res;
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return error as any;
      }
    },
    async ACTION_UPDATE_COMMUNE_GEOJSON_UUID(updateParams: UpdateCommuneParams) {
      const res = await CommuneService.UpdateCommuneGeoJsonByUuid(updateParams);
      if (res.status === 200) {
        this.communeActiveGeoJson = res.data;
      }
      return res;
    },

    /*----------  Commune filtering  ----------*/
    async ACTION_GET_ALL_COMMUNES_BY_STATUS(option: CommuneSelectedOption) {
      const { status, data } = await CommuneService.GetAllCommunesByStatus({ status: option.key });

      if (status === 200) {
        this.selectedCommune = new CommuneDtoImplementation();
        this.communeActiveGeoJson = null;
        // TODO Prepare all the communes to render to the map here
        this.communes = data;
      }
    },

    /*----------  Commune information  ----------*/
    async ACTION_COMMUNE_INFORMATION_UPDATE(
      q: V1CommuneUuidInfoParameters & { data: DeepRequired<CommuneDetailsDto>; scope?: string },
    ) {
      const { status, data } = await ReportServiceCommune.CommuneInformationUpdate(q);

      if (status === 200) {
        this.currentCommuneInformation = data;
      }
    },
    /*----------  GeoJson   ----------*/
    async ACTION_GEOJSON_BY_UUID(param: V1CommuneUuidAreaParameters) {
      const res = await CommuneService.GetAreaById(param);
      if (res.status === 200) {
        this.communeActiveGeoJson = res.data as unknown as string;
      }
      return res;
    },

    /*----------  GetAllDetail of commune by commune id  ----------*/
    async ACTION__GET_ALL_COMMUNE_DETAILS_BY_ID({ uuid }: V1CommuneUuidParameters, allDetails = true) {
      const res = await CommuneService.GetCommuneByUuid({ uuid });

      if (res.status === 200) {
        this.selectedCommune = new CommuneDtoImplementation(res.data);
        this.dropdownSelectedCommune = { value: this.selectedCommune.manualName, uuid: this.selectedCommune.uuid };

        if (!allDetails) return;

        try {
          await this.ACTION_GEOJSON_BY_UUID({ uuid });
          const communeInformation = await ReportServiceCommune.GetCommuneInformation({ uuid });

          const globalReportStore = useReportsAllStore();
          await globalReportStore.ACTION_GET_COMMUNE_STATS({ communeUuid: uuid });
          await globalReportStore.ACTION_GET_ALL_REPORTS_BY_COMMUNE({ p: { communeUuid: uuid } });

          this.currentCommuneInformation = communeInformation.data;
        } catch (error) {
          console.log(error);
        }
      }
      return res;
    },

    /*----------  Create commune public endpoint   ----------*/
    async ACTION_CREATE_COMMUNE_PUBLIC({ commune }: { commune: CommuneRegistrationDto }): Promise<CommuneRegistrationLayout> {
      const res = await ReportServiceCommune.RegisterCommune({ commune });
      if (res.status === 201) {
        return 'REGISTER_COMMUNE_SUCCESS';
      } else {
        return 'REGISTER_COMMUNE_ERROR';
      }
    },
    async ACTION_GET_ALL_PENDING_COMMUNES(p: V1CommuneregisterParameters) {
      const res = await ReportServiceCommune.GetAllPendingCommunes(p);
      if (res.status === 200) {
        this.pendingCommunes = res.data;
      }
    },
    async ACTION_GET_PENDING_COMMUNE_BY_UUID({ tempId }: V1CommuneregisterTempIdParameters) {
      const res = await ReportServiceCommune.GetPendingCommuneByUuId({ tempId });
      if (res.status === 200) {
        this.pendingCommuneDetails = res.data;
      }
    },
    async ACTION_UPDATE_PENDING_COMMUNE_BY_UUID(
      p: V1CommuneregisterTempIdParameters & { communeDetails: CommuneRegistrationDto },
    ) {
      const res = await ReportServiceCommune.UpdatePendingCommune(p);
      if (res.status === 200) {
        this.pendingCommuneDetails = res.data;
      }
    },
    async ACTION_CONFIRM_PENDING_COMMUNE_BY_UUID({ tempId }: V1CommuneregisterTempIdParameters) {
      return await ReportServiceCommune.ConfirmCommune({ tempId });
    },
    async ACTION_DELETE_PENDING_COMMUNE_BY_UUID({ tempId }: V1CommuneregisterTempIdParameters) {
      return await ReportServiceCommune.DeletePendingCommune({ tempId });
    },

    /*----------  New report  ----------*/
    async ACTION_CHECK_LOCATION_INSIDE_COMMUNE(p: { long: number; lat: number }): Promise<'IN_COMMUNE' | 'OUTSIDE_COMMUNE'> {
      if (!this.selectedCommune?.uuid) return 'OUTSIDE_COMMUNE';
      try {
        const res = await CommuneService.IsMarkerWhitInCommune({ ...p, communeUuId: this.selectedCommune.uuid });
        if (res.status === 200 && res.data === true) {
          return 'IN_COMMUNE';
        }
        return 'OUTSIDE_COMMUNE';
      } catch (error) {
        return 'OUTSIDE_COMMUNE';
      }
    },

    /*----------  Defined commune  ----------*/
    async ACTION_QUERY_PREDEFINED_COMMUNES(q: V1StubParameters) {
      return await CommuneService.FetchPredefinedCommunes(q);
    },
    async ACTION_FORCE_SYNC_BY_COMMUNE_UUID(q: V1CommuneUuidNotifyParameters) {
      return await CommuneService.SyncCommuneByUuId(q);
    },
    async ACTION_FORCE_SYNC_BY_COMMUNE_UUID_REPORT(q: V1CommuneUuidSyncParameters) {
      return await ReportServiceCommune.SyncCommuneByUuId(q);
    },
    async ACTION_SYNC_ALL_REPORTS_BY_COMMUNE_UUID(q: V1CommuneUuidSyncParameters) {
      return await ReportServiceCommune.SyncAllReportToAndFrom(q);
    },
    async ACTION_CHECK_COMMUNE_URL(q: { newUrl: string; uuid: string }) {
      return await CommuneService.ValidateCommuneUrl(q);
    },

    /*----------  Check if coordinates are in commune  ----------*/

    async ACTION_CHECK_LAT_LONG_IN_COMMUNE({
      long,
      lat,
    }: {
      long: string;
      lat: string;
    }): Promise<{ uuid: string; manualName: string; latitude: number; longitude: number; usable: boolean }[]> {
      const request = await CommuneService.GetAllCommunesBaseOnTheCoordinates({ long, lat });
      if (request.status === 200) {
        return request.data;
      } else {
        return [];
      }
    },

    /*---------- Communes need sync from report service  ----------*/
    async ACTION_CHECK_COMMUNES_NEED_SYNC() {
      try {
        const res = await ReportServiceCommune.GetAllCommunesThatNeedSync();
        if (res.status === 200) {
          this.communesThatNeedSync = res.data;
        }
      } catch (error) {
        return false;
      }
    },

    async ACTION_SYNC_ALL_COMMUNES() {
      //Request the force sync for all the communes async

      for await (const item of this.communesThatNeedSync) {
        this.ACTION_FORCE_SYNC_BY_COMMUNE_UUID_REPORT({ uuid: item.uuid, action: 'SYNC_ONLY' });
      }
      await this.ACTION_CHECK_COMMUNES_NEED_SYNC();
    },
  },
});
