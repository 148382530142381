import './styles/tailwind.scss';
import 'leaflet/dist/leaflet.css';

import { Icon } from 'leaflet';
import { create, NButton } from 'naive-ui';
import { createPinia } from 'pinia';

import { ComponentPublicInstance, createApp } from 'vue';
import App from './App.vue';
import { i18n } from './i18n';
import './registerServiceWorker';
import router from './router';

import { createHead } from '@unhead/vue';
import { plugin } from '@formkit/vue';
import { customConfig } from '@/config/FormkitConfig';
import { registerLayout } from '@/layouts/Register';
import './oidc';
Icon.Default.mergeOptions({
  iconRetinaUrl: import('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: import('leaflet/dist/images/marker-icon.png'),
  shadowUrl: import('leaflet/dist/images/marker-shadow.png'),
});
declare module 'vue-router' {
  interface Router {
    app: ComponentPublicInstance;
  }
}
const naive = create({
  components: [NButton],
});
const app = createApp(App).use(createHead()).use(naive).use(i18n).use(createPinia()).use(plugin, customConfig).use(router);
registerLayout(app);
app.mount('#app');

// router.app = app;
